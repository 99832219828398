import classNames from "classnames";
import moment from "moment";
import Link from "next/link";
import React from "react";
import { IBaseBox } from "../../model/base";
import styles from "./newestPosts.module.scss";
import Image from "next/legacy/image";
import { stripHtml, truncate } from "../../util/functionGlobal";
interface ItemPostProps {
  post: IBaseBox;
  fristIndex: boolean;
}

export default function ItemPost(props: ItemPostProps) {
  const { post, fristIndex } = props;

  const imageUrl = post?.image_src ? process.env.NEXT_PUBLIC_BASE_IMAGE_URL + `/articles/${post?.image_src}` : process.env.NEXT_PUBLIC_NO_IMAGE;
  return (
    <div className={classNames(styles["no-padding-es"], "col-lg-4 col-sm-6")}>
      <div className={styles["post-item"]}>
        <div className={styles["post-thumb"]}>
          <Link href={`/${post.alias}`} className={styles["img-thumb"]}>
            <Image loading={fristIndex ? "eager" : "lazy"} objectFit="cover" layout="fill" quality={100} src={imageUrl} alt={post?.title} />
          </Link>
          {post?.categories.length > 0 && (
            <ul className={styles["post-categories"]}>
              {post?.categories?.map((item, index) => (
                <li key={index}>
                  {item?.alias?.includes("/") ? (
                    <a href={`${process.env.NEXT_PUBLIC_BASE_URL + process.env.NEXT_PUBLIC_BASE_PATH}/${item?.alias}`} title={item.title}>
                      {item.title}
                    </a>
                  ) : (
                    <Link href={`/${item?.alias}`}>{item?.title}</Link>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className={classNames(styles["post-info"], "d-flex align-items-center justify-content-between")}>
          <span>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="clock"
              className="svg-inline--fa fa-clock fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
              ></path>
            </svg>
            {post.published_at ? moment(post.published_at).format("DD-MM-YYYY") : post.created_at ? moment(post.created_at).format("DD-MM-YYYY") : ""}
          </span>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 469.33 320">
              <g>
                <path d="M234.67,96a64,64,0,1,0,64,64A64,64,0,0,0,234.67,96Z" />
                <path d="M234.67,0C128,0,36.91,66.35,0,160c36.91,93.65,128,160,234.67,160s197.76-66.35,234.66-160C432.43,66.35,341.44,0,234.67,0Zm0,266.67A106.67,106.67,0,1,1,341.33,160,106.71,106.71,0,0,1,234.67,266.67Z" />
              </g>
            </svg>
            {post.article_view_counts?.count || 0} Lượt xem
          </span>
        </div>
        <h3>
          <Link href={`/${post?.alias}`} className={styles.title}>
            {post.title}
          </Link>
        </h3>
        <div className={styles.summary}>
          <p
            dangerouslySetInnerHTML={{
              __html: post.content1 ? truncate(stripHtml(post.content1)) : "",
            }}
          />
        </div>
      </div>
    </div>
  );
}
