import React from "react";
import { IBaseBox } from "../../model/base";
import ItemPost from "./itemPost";

interface NewestPostsProps {
  posts: IBaseBox[];
  fristIndex?: boolean;
}

export default function NewestPosts(props: NewestPostsProps) {
  const { posts, fristIndex } = props;
  return posts ? (
    <>
      {posts.map((post, index) => (
        <ItemPost fristIndex={fristIndex && index === 0} key={index} post={post} />
      ))}
    </>
  ) : (
    <></>
  );
}
